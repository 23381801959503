import React,{useState,useEffect,useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import useForm from "./useForm";
import validate from './FormValidationRules';
import countrylist from "../../data/country.json";
import { Modal } from 'react-responsive-modal';
import TermsAndCondition from './TermsAndCondition';
import UpdateAddressForm from './UpdateAddressForm';
import SuggestedAddressPopup from './SuggestedAddressPopup';
import Loader from './Loader';

const Form = () => {
  const [isTCStatus, setisTCStatus] = useState(-1);
  const [open, setOpen] = useState(false);
  const [supi, setSupi] = useState();
  const [gid1, setGid1] = useState('');
  const [gid2, setGid2] = useState('');
  const [type, setType] = useState('');
  const [deviceImei, setDeviceImei] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const modalRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [showSuggestedPopup, setShowSuggestedPopup] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [responseAddress, setResponseAddress] = useState();
  const SERVER_URL = process.env.REACT_APP_ADD_ADDRESS_SERVER_URL;
  const WholesaleCustomerId = process.env.REACT_APP_WholesaleCustomerId;
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const inputRef = useRef([]);

  useEffect(() => {
    // Update the document title using the browser API
    const queryParameters = new URLSearchParams(window.location.search)
    const tc_status = queryParameters.get("tc-status");
    const tc = queryParameters.get("TC");
    const supi = queryParameters.get("SUPI");
    const device_imsi = queryParameters.get("device-imsi");
    const gid1 = queryParameters.get("GID1") ? queryParameters.get("GID1") : queryParameters.get("gid1") ;
    const gid2 = queryParameters.get("GID2") ? queryParameters.get("GID2") : queryParameters.get("gid2") ;
    const device_imei = queryParameters.get("device-imei");
    const type = queryParameters.get("type");
    setGid1(gid1);
    setGid2(gid2);
    setSupi(supi);
    type && setType(type.toLowerCase());
    device_imei && setDeviceImei(device_imei.trim());
    /*Reference from PHP Start*/
    if (device_imsi && device_imsi !== "") {
      tc_status && setisTCStatus(parseInt(tc_status.trim()));
      device_imsi && setSupi(device_imsi.trim())
    } else {
      let parseTC = tc_status != null ? tc_status : tc;
      parseTC && setisTCStatus(parseInt(parseTC.trim()));
      supi && setSupi(supi.trim())
    }
    /*Reference from PHP End*/
   }, []);
  
   useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const callGetAddress = location.pathname === '/e911' &&  (parseInt(queryParameters.get("tc-status")) === 0 || parseInt(queryParameters.get("TC")) === 0)
   
    if(!callGetAddress){
      
      let data = {
        "pei": queryParameters.get("device-imei"),
        "getaddress": true
        }
       
      axios.post(SERVER_URL, data)
        .then(response => {
          if(response?.data?.address){
            values.street_1 = response?.data?.address?.addressLine1;
            values.street_2 = response?.data?.address?.addressLine2;
            values.city = response?.data?.address?.city;
            values.region_id = response?.data?.address?.state;
            values.postcode = response?.data?.address?.zip;
          }
        })
        .catch(error => {
            console.log(error)
        });
    }

  }, [SERVER_URL]); 

  const {
    values,
    focusElement,
    errors,
    handleChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    resetForm
  } = useForm(submitAction, validate,isTCStatus);

  useEffect(() => {
   
    let firstKey  = errors && Object.keys(errors).length === 0
    && Object.getPrototypeOf(errors) === (Object.prototype !== "") ? 'street_1' : Object.keys(errors)[0];
    
    if(!isValid){
      if (inputRef.current[firstKey]) {
        if(inputRef.current[firstKey].type === 'checkbox' ){
          inputRef.current[firstKey].focus();
          inputRef.current[firstKey].select();
        }else{
          inputRef.current[firstKey].focus();
        }
        inputRef.current[firstKey].focus();
      }
    }
   }, [errors,isValid]);

   // Add a request interceptor
   axios.interceptors.request.use(function (config) {
    setShowLoader(true);
    return config;
  }, function (error) {
    setShowLoader(false);
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    setShowLoader(false);
    return response;
  }, function (error) {
    setShowLoader(false);
    return Promise.reject(error);
  });


  function submitAction() {
    if(inputRef.current['supi'].value.length === 0 || inputRef.current['deviceImei'].value.length === 0){
      setMessage('Invalid information - Unable to complete request.');
      setShowMessage(true);
      window.scrollTo(0, 0)
      return;
    }
    let data = {
      "supi": (inputRef.current['supi'].value)?.trim(),
      "wholesaleCustomerId": WholesaleCustomerId,
      "address": {
        "addressLine1": values.street_1?.trim(),
        "addressLine2": values.street_2?.trim() ? values.street_2?.trim() : '' ,
        "city": values.city?.trim(),
        "state": values.region_id?.trim(),
        "zip": values.postcode?.trim()
      },
      "pei": inputRef.current['deviceImei'].value,
      "brand": gid1 ? gid1?.trim() : gid2?.trim(),
      "changeAddress": true,
    }
    if(isTCStatus === 0){
      data['acknowledgements'] = [
        {
          "name":"unavailable-limited",
          "value":"1"
        },
        {
          "name":"fcc",
          "value":"1"
        },
        {
          "name":"terms_conditions",
          "value":"1"
        }
      ];
    }
    
    callAPI(data);
  }
  function callAPI(data){
    axios.post(SERVER_URL, data)
      .then(response => {
        setResponseAddress(response);
        if(response?.data?.smartyAddress){
          setShowSuggestedPopup(true);
          setShowMessage(false);
          setMessage( response.data.message);
        } else if (!response?.data?.success){
          setMessage('There was an error while processing the request.' );
          setShowMessage(true);
          setSuccess(response?.data?.success);
        } else {
          setMessage(response?.data?.success ? 'Request has been successfully submitted!' : response.data.message );
          setSuccess(response?.data?.success);
          if(response?.data?.success){
            resetForm();
          }
        if(inputRef.current['unavailablelimited'])
            inputRef.current['unavailablelimited'].checked = false ;
          if(inputRef.current['fcc'])
            inputRef.current['fcc'].checked = false ;
          if(inputRef.current['termsconditions'])
            inputRef.current['termsconditions'].checked = false ;
          setShowMessage(true);
          window.scrollTo(0, 0);
        }
      })
      .catch(error => {
          setMessage(error.message);
          setSuccess(false);
      }).finally(res => {
        
    });
    setIsValid(true);
  }
  const closeSuggestedAddressPopup = () =>{
    setShowSuggestedPopup(false);
    setSuccess(false);
    setShowMessage(true);
    window.scrollTo(0, 0)
  }
  const populateAddress = () =>{
    values.street_1 = responseAddress?.data?.smartyAddress?.delivery_line_1;
    values.city = responseAddress?.data?.smartyAddress?.components?.city_name;
    values.region_id = responseAddress?.data?.smartyAddress?.components?.state_abbreviation;
    values.postcode = responseAddress?.data?.smartyAddress?.components?.zipcode;
    setShowMessage(false);
    setShowSuggestedPopup(false);
  }
  const onClickCancel = (e) => {
    e.preventDefault();
    if(type === 'apple'){
      // eslint-disable-next-line no-undef
      WiFiCallingWebViewController.cancelButtonPressed();
    } else{
      // eslint-disable-next-line no-undef
      VoWiFiWebServiceFlow.dismissFlow(); 
    }
  }
  const location = useLocation();
  return (
    <div className="section is-fullheight">
      {(responseAddress && responseAddress.data.success && type === 'apple') && <HelmetProvider>
        <Helmet>
          <script>
              WiFiCallingWebViewController.phoneServicesAccountStatusChanged(true);
          </script>
        </Helmet>
      </HelmetProvider> }

      {(responseAddress && responseAddress.data.success && type !== 'apple') && <HelmetProvider>
        <Helmet>
          <script>
              VoWiFiWebServiceFlow.entitlementChanged();
          </script>
        </Helmet>
      </HelmetProvider> }
      
      <UpdateAddressForm isAddress={location.pathname} onClickCancel={(e)=>{ onClickCancel(e)}}/>

      {(responseAddress?.data?.smartyAddress && Object.keys(responseAddress?.data?.smartyAddress).length) && <SuggestedAddressPopup showSuggestedPopup={showSuggestedPopup} 
         responseAddress={responseAddress} closeSuggestedAddressPopup={closeSuggestedAddressPopup}
          populateAddress={populateAddress}/> }
         
      <div className="container">
        <h1 className="page-title">
                E911  
        </h1>
        {showMessage && 
          <div className="messages" > 
            <div className={`message ${success ? 'message-success success': 'message-error error'} ` } > 
              {message}
            </div> 
          </div>
        }
        {showLoader && <Loader/>}
        <div className="column is-4 is-offset-4">
          <div className="box">
            <form onSubmit={handleSubmit} noValidate>
              <fieldset>
                <legend className="legend">Update Address Information</legend>
                <div className={`field required ${values.street_1 && 'form-field--is-filled'} ${focusElement==='street_1' && 'form-field--is-active'}` }>
                  <label className="label">Address 1</label>
                    <div className="control">
                        <input autoComplete="off" className={`input ${errors.street_1 && 'is-danger'} `}
                        type="text" name="street_1" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
                        value={values.street_1 || ''} required  ref={el => inputRef.current['street_1'] = el} />
                        {errors.street_1 && (
                        <p className="help is-danger">{errors.street_1}</p>
                        )}
                    </div>
                </div>

                <div className={`field ${values.street_2 && 'form-field--is-filled'} ${focusElement==='street_2'&& 'form-field--is-active'}`}>
                    <label className="label">Address 2</label>
                    <div className="control">
                        <input autoComplete="off" className={`input ${errors.street_2 && 'is-danger'}`}
                        type="text" name="street_2" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} 
                        value={values.street_2 || ''}  ref={el => inputRef.current['street_2'] = el}/>
                        
                    </div>
                </div>

                <div className={`field required ${values.city && 'form-field--is-filled'}  ${focusElement==='city'&& 'form-field--is-active'}`}>
                    <label className="label">City</label>
                    <div className="control">
                        <input autoComplete="off" className={`input ${errors.city && 'is-danger'}`}
                        type="text" name="city" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
                         value={values.city || ''} required ref={el => inputRef.current['city'] = el}/>
                        {errors.city && (
                        <p className="help is-danger">{errors.city}</p>
                        )}
                    </div>
                </div>
                
                <div className={`field required ${values.region_id && 'form-field--is-filled'} ${focusElement==='region_id'&& 'form-field--is-active'}`}>
                    <label className="label">State</label>
                    <div className="control">
                      <select id="region_id" name="region_id" onChange={handleChange} onBlur={handleBlur}
                      onFocus={handleFocus} value={values.region_id || ''} required ref={el => inputRef.current['region_id'] = el}>
                        <option value=" ">Select state</option>
                            {countrylist && countrylist.US.map((obj, key) => {
                            return <option key={key} value={obj.code}>{obj.name}</option>
                            })}
                      </select>
                      {errors.region_id && (
                      <p className="help is-danger">{errors.region_id}</p>
                      )}
                    </div>
                </div>

                <div className={`field required ${values.postcode && 'form-field--is-filled'} ${focusElement==='postcode'&& 'form-field--is-active'}`}>
                    <label className="label">Zip</label>
                    <div className="control">
                    <input autoComplete="off" className={`input ${errors.postcode && 'is-danger'}`}
                    type="text" name="postcode" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} 
                    value={values.postcode || ''} required ref={el => inputRef.current['postcode'] = el}/>
                    {errors.postcode && (
                        <p className="help is-danger">{errors.postcode}</p>
                    )}
                    </div>
                </div>

          {isTCStatus === 0 && 
              <div className="tc-required">
            <p className="mb-20">
                To ensure this Voice over Wi-Fi (VoWiFi) service meets Federal Communications Commission (FCC) requirements for 911 service, 
                we need some information from you before initiating your VoWiFi service.</p>
            <p className="mb-10"> As a condition of using this service, please indicate your agreement with these terms
             and conditions:</p>           
            <div className="field choice required">
                <input type="checkbox" name="unavailablelimited" title="Unavailable Or Limited" 
                value="1" id="unavailable-limited" className="checkbox required" 
                aria-invalid="true" aria-describedby="unavailable-limited-error" onChange={handleChange} ref={el => inputRef.current['unavailablelimited'] = el}/>
                <label htmlFor="unavailable-limited">
                    <span className="terms-label">l understand and agree that 911 services may be unavailable or limited in 
                    certain circumstances, including but not limited to:</span>
                        <ul>
                            <li>Attempting to use VoWiFi to call from locations that may have weak or unavailable global positioning (GPS) signals, such as inside buildings or under structures such as parking garages or bridges.</li>
                            <li>Attempting to place a VoWiFi location outside the range of a Wi-Fi access point, or "hotspot.</li>
                        </ul>                
                </label>
                {errors.unavailablelimited && (
                    <p className="help is-danger">{errors.unavailablelimited}</p>
                )}
            </div>
            <div className="field choice required">
                <input type="checkbox" name="fcc" title="FCC" value="1" id="fcc" className="checkbox required mage-error"
                 aria-invalid="true" aria-describedby="fcc-error" onChange={handleChange} ref={el => inputRef.current['fcc'] = el}/>
                <label htmlFor="fcc">
                    <span className="terms-label">l understand and agree that to provide 911 services as required by the FCC 
                    and to allow for emergency response, the personal information that I supply will be disclosed in addition to device-based location information, including location coordinates, without additional notice to me.
                    </span>
                </label>
                {errors.fcc && (
                    <p className="help is-danger">{errors.fcc}</p>
                )}
            </div>
            <div className="field choice required mb-20">
                <input type="checkbox" name="termsconditions" title="Terms and Conditions" value="1" 
                id="terms_conditions" className="checkbox required mage-error" aria-invalid="true" 
                aria-describedby="terms_conditions-error" onChange={handleChange} ref={el => inputRef.current['termsconditions'] = el}/>
                <label htmlFor="terms_conditions">
                    <span className="terms-label">I have read and agreed to the full</span>
                    <button type="button" className="terms-label action text-underline" id="terms-btn"  onClick={onOpenModal} data-trigger="trigger">
                    Terms of Service.
                    </button>
                </label>
                
                {open &&  <Modal  ref={modalRef}  initialFocusRef={modalRef}  open={open} center onClose={onCloseModal}>
                           <TermsAndCondition/>
                           <button type="button" className="button is-block is-info primary-btn" onClick={onCloseModal}>OK</button>
                    </Modal>  }
                {errors.termsconditions && (
                    <p className="help is-danger">{errors.termsconditions}</p>
                )}
            </div>
            <p>
                <span className="mb-10">We also need a location where you want 911 first responders to search for you 
                when you dial 911.</span>
                <span className="mb-10">In the form that follows, please provide the U.S. civic address where you want emergency 
                services to search for you if you call 911 using the VoWiFi service (you may not use a P.O.Box) 
                ("Registered Location"). You may change your Registered Location at any time in your Wi-Fi Calling settings 
                and you must keep your Registered Location up to date to ensure proper routing of 911 calls and emergency services
                when using the VoWiFi service.</span>     
             </p>  
              </div>
              }

                <input type="hidden" name="supi" defaultValue={supi} ref={el => inputRef.current['supi'] = el}/>
                <input type="hidden" name="gid1" defaultValue={gid1} ref={el => inputRef.current['gid1'] = el}/>
                <input type="hidden" name="gid2" defaultValue={gid2} ref={el => inputRef.current['gid2'] = el}/>
                <input type="hidden" name="imei" defaultValue={deviceImei} ref={el => inputRef.current['deviceImei'] = el}/>
                <input type="hidden" name="tc-status" defaultValue={isTCStatus} ref={el => inputRef.current['isTCStatus'] = el}/>
                
                <div className={`${Number(isTCStatus)?'mt-50':'mt-0'}`}>
                  <button type="submit" className="button is-block is-info primary-btn" ref={el => inputRef.current['submitbtn'] = el}>Submit</button>
                  <button type="cancel" className="button is-block is-info primary-btn" onClick={(e)=>{ onClickCancel(e)}}>Cancel</button>
                </div>
              </fieldset>
            
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;